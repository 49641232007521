import axios from '@/libs/axios'

class Tutor {
  static Schedule(params) {
    return axios.post('admin/teacher/schedule', params)
  }

  static RateUpdate(params) {
    return axios.post('admin/teacher/schedule/update-rate', params)
  }

  static AttendanceUpdate(params) {
    return axios.post('admin/teacher/schedule/update-attendance', params)
  }
}

export default Tutor
