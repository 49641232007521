<template>
  <div>
    <!-- Filters -->
    <shared-tutor-schedule-filters
      :level-options="levelOptions"
      :date-filter.sync="filter.date"
      :time-of-call-filter.sync="filter.time_of_call"
      :level-filter.sync="filter.level"
      :content-name-filter.sync="filter.content_name"
      @click="loadData"
    />
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.10"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="mb-0"
          :tbody-tr-class="rowClass"
        >

          <template #cell(name)="data">
            <div
              v-if="data.item.schedules !== null"
            >
              <template
                v-for="(schedule, scheduleStudentNameIndex) in data.item.schedules"
              >
                <p
                  v-if="schedule.enrollment !== null && schedule.enrollment.student !== null"
                  :key="scheduleStudentNameIndex"
                  class="align-middle mb-0"
                  v-text="schedule.enrollment.student.name"
                />
              </template>
            </div>
          </template>

          <template #cell(rate)="data">
            <div
              v-if="data.item.schedules !== null"
            >
              <template
                v-for="(schedule, scheduleRateIndex) in data.item.schedules"
              >
                <span
                  :key="scheduleRateIndex"
                >
                  <p
                    v-if="schedule.rate !== null"
                    class="align-middle mb-0 clickable"
                    @click="setRate(schedule)"
                    v-text="schedule.rate"
                  />
                  <p
                    v-else
                    class="align-middle mb-0 clickable"
                    @click="setRate(schedule)"
                    v-text="'Set Rate'"
                  />
                </span>
              </template>
            </div>
          </template>

          <template #cell(attendance)="data">
            <div
              v-if="data.item.schedules !== null"
            >
              <template
                v-for="(schedule, scheduleAttendanceIndex) in data.item.schedules"
              >
                <span
                  :key="scheduleAttendanceIndex"
                >
                  <p
                    v-if="schedule.status !== null"
                    class="align-middle mb-0 clickable"
                    @click="setAttendance(schedule)"
                    v-text="schedule.status"
                  />
                  <p
                    v-else
                    class="align-middle mb-0 clickable"
                    @click="setAttendance(schedule)"
                    v-text="'Set Attendance'"
                  />
                </span>
              </template>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              href="#"
              class="card-link btn btn-primary"
              @click="enterRoom(data.item)"
            >
              Enter Class
            </b-link>
          </template>

        </b-table>
        <template slot="footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </template>
      </b-card>
    </b-overlay>

    <b-modal
      ref="set-rate-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Set Rate"
      @ok="setRateHandle"
      @close="setRateResetFormData"
      @cancel="setRateResetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="setRateHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('student_name') }}</label>
                <b-form-input
                  v-model="rateFormData.name"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('rate')"
                  rules="required"
                >
                  <label>{{ $t('rate') }}</label>
                  <v-select
                    v-model="rateFormData.rate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="rateOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="set-attendance-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="lg"
      title="Set Attendance"
      @ok="setAttendanceHandle"
      @close="setAttendanceResetFormData"
      @cancel="setAttendanceResetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="setAttendanceHandleSubmit">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <label>{{ $t('student_name') }}</label>
                <b-form-input
                  v-model="attendanceFormData.name"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('attendance')"
                  rules="required"
                >
                  <label>{{ $t('attendance') }}</label>
                  <v-select
                    v-model="attendanceFormData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="attendanceOptions"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BPagination,
  BOverlay,
  BLink,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Tutor from '@/models/Tutor'
import Engducate from '@/models/Engducate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SharedTutorScheduleFilters from '../Shared/Tutor/Schedule/Filter.vue'

export default {
  name: 'MySchedule',
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    SharedTutorScheduleFilters,
    BCard,
    BTable,
    BPagination,
    BOverlay,
    BLink,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
      fields: [
        'date',
        'time_of_call',
        'level',
        'content_name',
        'total_students',
        'name',
        'rate',
        'attendance',
        'actions',
      ],
      rateFormData: {
        id: '',
        rate: '',
        name: '',
      },
      attendanceFormData: {
        id: '',
        status: '',
        name: '',
      },
      rateOptions: [
        { label: '0', value: '0' },
        { label: '0.25', value: '0.25' },
        { label: '0.50', value: '0.50' },
        { label: '0.75', value: '0.75' },
        { label: '1', value: '1' },
        { label: '1.25', value: '1.25' },
        { label: '1.50', value: '1.50' },
        { label: '1.75', value: '1.75' },
        { label: '2', value: '2' },
        { label: '2.25', value: '2.25' },
        { label: '2.50', value: '2.50' },
        { label: '2.75', value: '2.75' },
        { label: '3', value: '3' },
        { label: '3.25', value: '3.25' },
        { label: '3.50', value: '3.50' },
        { label: '3.75', value: '3.75' },
        { label: '4', value: '4' },
        { label: '4.25', value: '4.25' },
        { label: '4.50', value: '4.50' },
        { label: '4.75', value: '4.75' },
        { label: '5', value: '5' },
      ],
      attendanceOptions: [
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'Attend', value: 'Attend' },
        { label: 'Absent', value: 'Absent' },
        { label: 'Teacher Cancel', value: 'Teacher Cancel' },
      ],
      items: [],
      loading: true,
      perPage: 10,
      totalPage: 0,
      levelOptions: [],
      filter: {
        date: typeof this.$route.query.date !== 'undefined' ? this.$route.query.date : '',
        time_of_call: typeof this.$route.query.time_of_call !== 'undefined' ? this.$route.query.time_of_call : '',
        level: typeof this.$route.query.level !== 'undefined' ? parseInt(this.$route.query.level, 0) : null,
        content_name: typeof this.$route.query.content_name !== 'undefined' ? this.$route.query.content_name : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      Object.keys(this.filter).forEach(e => {
        if (e === 'level') {
          if (parseInt(this.filter[e], 0) > 0) {
            searchParams[e] = parseInt(this.filter[e], 0)
          }
        } else if (this.filter[e] !== null && this.filter[e].length > 0) {
          searchParams[e] = this.filter[e]
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Tutor.Schedule(searchParams)
      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.filter.page = response.data.meta.pagination.current_page
      this.levelOptions = response.data.meta.options.levels
      this.loading = false
    },
    async enterRoom(schedule) {
      try {
        const response = await Engducate.create({
          talkId: schedule.id,
          redirectUrl: window.location.href,
        })
        window.open(response.data.url)
      } catch (e) {
        alert(e.response.data ?? e.message)
      }
    },
    rowClass(item, type) {
      const colorClass = 'schedule-today'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.today === true) { return colorClass }
    },
    setRateResetFormData() {
      this.rateFormData = {
        id: '',
        rate: '',
        name: '',
      }
    },
    async setRate(data) {
      this.setRateResetFormData()

      try {
        this.rateFormData.id = data.id
        this.rateFormData.rate = data.rate
        if (data.enrollment !== null && data.enrollment.student !== null) {
          this.rateFormData.name = data.enrollment.student.name
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['set-rate-modal'].show()
    },
    setRateHandle(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.setRateHandleSubmit()
    },
    async setRateHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await Tutor.RateUpdate(this.rateFormData)

          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['set-rate-modal'].hide()
          this.setRateResetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    setAttendanceResetFormData() {
      this.attendanceFormData = {
        id: '',
        status: '',
        name: '',
      }
    },
    async setAttendance(data) {
      this.setAttendanceResetFormData()

      try {
        this.attendanceFormData.id = data.id
        this.attendanceFormData.status = data.status
        if (data.enrollment !== null && data.enrollment.student !== null) {
          this.attendanceFormData.name = data.enrollment.student.name
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['set-attendance-modal'].show()
    },
    setAttendanceHandle(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.setAttendanceHandleSubmit()
    },
    async setAttendanceHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await Tutor.AttendanceUpdate(this.attendanceFormData)

          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['set-attendance-modal'].hide()
          this.setAttendanceResetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.levels-checkbox-container .custom-checkbox, .times-checkbox-container .custom-checkbox {
  margin-bottom: 5px;
}
.schedule-today, .schedule-today > th, .schedule-today > td {
  background-color: #ffc9c9;
}

.dark-layout .schedule-today, .dark-layout .schedule-today > th, .dark-layout .schedule-today > td {
  background-color: green;
  color: #fff !important;
}
.clickable {
  cursor: pointer;
}
</style>
